import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface IMainLayout {
  children: ReactNode;
  className?: string;
}

const MainLayout: FC<IMainLayout> = ({ className = '', children }) => {
  return <main className={twMerge(`main py-10 ${className}`)}>{children}</main>;
};

export default MainLayout;
