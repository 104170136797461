import { FC } from 'react';
import { ImSpinner9 } from 'react-icons/im';
import { twMerge } from 'tailwind-merge';

interface IZpSpinner {
  size?: number;
  hasHeight?: boolean;
  color?: string;
  className?: string;
}

const ZpSpinner: FC<IZpSpinner> = ({ size, color, hasHeight = true, className }) => {
  return (
    <div className={twMerge(`flex items-center justify-center w-full ${hasHeight ? 'h-48' : 'h-auto'} ${className}`)}>
      <ImSpinner9 className={`spinner ${color ? color : 'text-success-color-300'}`} size={size || 40} />
    </div>
  );
};

export default ZpSpinner;
