'use client';

import Link, { LinkProps } from 'next/link';
import { AnchorHTMLAttributes, FC, ReactNode } from 'react';
import { usePathname } from 'next/navigation';

interface ZpLink extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  external?: boolean;
  relativePath?: boolean;
  disabled?: boolean;
  className?: string;
}

const ZpLink: FC<ZpLink & LinkProps> = ({ children, prefetch = true, disabled = false, target, className = '', href, relativePath = false, external = false, ...props }) => {
  const asPath = usePathname();

  return external ? (
    <a href={`${process.env.NEXT_PUBLIC_DOMAIN}${href}`} className={className} target={target}>
      {children}
    </a>
  ) : (
    <Link prefetch={prefetch} href={relativePath ? `${asPath.substring(0, asPath.length - 1)}${href}` : href} className={`${disabled ? 'disabled' : ''} ${className}`} {...props}>
      {children}
    </Link>
  );
};

export default ZpLink;
