'use client';

import Image, { ImageProps } from 'next/image';
import { FC, useEffect, useState } from 'react';

const ZpImage: FC<ImageProps> = ({ loading = 'eager', className, src, alt, ...props }) => {
  const [imageSrc, setImageSrc] = useState(src);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  return (
    <Image
      alt={alt || 'no alt'}
      src={imageSrc}
      className={className}
      onError={() => {
        setImageSrc(`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/no-image.png`);
      }}
      loading={loading}
      {...props}
    />
  );
};

export default ZpImage;
