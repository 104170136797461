export const routes = Object.freeze({
  // Root
  ROOT: (id?: string) => `/${id ? `#${id}` : ''}`,

  // login
  LOGIN: (query?: string) => `/login${query ? `?${query}` : ''}`,

  // payment link
  PAYMENT_LINK: (paymentLinkId: string | number, username: string) => `/${username}/payment/${paymentLinkId}`,

  // panel
  PANEL_PLAN: '/panel/plans',

  // other pages
  PRIVACY: '/privacy',
  RULES: '/rules',
});
