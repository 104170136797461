import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import ZpSpinner from '../Spinner/ZpSpinner';
import style from './button.module.scss';

interface IZpButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | ReactNode;
  className?: string;
  isLoading?: boolean;
  buttonType?: 'primary' | 'transparent' | 'ghost';
  buttonSize?: 'normal' | 'small';
  width?: 'full';
  buttonFormWrapper?: boolean;
  buttonIcon?: ReactNode;
}

const ZpButton: FC<IZpButton> = ({
  children,
  buttonIcon,
  buttonFormWrapper,
  buttonType = 'primary',
  buttonSize = 'normal',
  className = '',
  width = '',
  isLoading = false,
  ...props
}) => {
  const buttonIconContent = (
    <span className='flex items-center justify-center space-x-3 space-x-reverse'>
      <span>{children}</span>
      {buttonIcon}
    </span>
  );

  const Content = isLoading ? <ZpSpinner color='text-white-color' size={20} hasHeight={false} /> : buttonIcon ? buttonIconContent : children;

  return (
    <button
      className={twMerge(
        `text-center justify-center ${style.btn} ${buttonFormWrapper ? 'mt-4' : ''} ${style[`btn--${buttonType}`]} ${style[`btn--${buttonSize}`]} ${
          width === 'full' ? 'w-full' : ''
        } ${className}`
      )}
      {...props}
    >
      {Content}
    </button>
  );
};

export default ZpButton;
