import MainLayout from 'components/Layouts/MainLayout/MainLayout';
import { FC, ReactNode } from 'react';
import LinkPageFooter from 'components/Layouts/LinkPageFooter/LinkPageFooter';

export interface INotFountLayout {
  children: ReactNode;
}

const NotFountLayout: FC<INotFountLayout> = ({ children }) => {
  return (
    <>
      <button
        onClick={() => {
          throw new Error('newwwwwww');
        }}
      >
        Break the world
      </button>
      <MainLayout className='flex items-center justify-center h-full'>{children}</MainLayout>
      <LinkPageFooter />
    </>
  );
};

export default NotFountLayout;
