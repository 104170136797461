'use client';

import { FC } from 'react';
import Container from 'components/Layouts/Container/Container';
import ZpImage from 'components/UI/Image/ZpImage';
import ZpButton from 'components/UI/Button/ZpButton';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { useIsServerSide } from 'core/hooks/useIsServerSide';
import NotFountLayout from 'components/Layouts/PagesLayouts/NotFountLayout/NotFountLayout';
import { useRouter } from 'next/navigation';

const NotFound: FC = () => {
  // store
  const { theme } = useSelector((store: RootState) => store.settingThemeStore);

  // hooks
  const { isServerSide } = useIsServerSide();
  const { back } = useRouter();

  return (
    <NotFountLayout>
      <Container>
        <div className='flex flex-col  space-y-10 items-center justify-center h-full'>
          {!isServerSide && <ZpImage src={`${process.env.NEXT_PUBLIC_LOCAL_IMAGES_BASE_URL}/404-${theme}.svg`} width={500} height={400} alt='404 icon' />}
          <p className='text-lg'>متاسفانه صفحه مورد نظر یافت نشد!</p>
          <ZpButton className='w-28 bg-primary-color-main hover:bg-primary-color-300 text-white-color text-center py-3 px-4 rounded-lg' onClick={back}>
            بازگشت
          </ZpButton>
        </div>
      </Container>
    </NotFountLayout>
  );
};

export default NotFound;
