'use client';

import ZpImage from 'components/UI/Image/ZpImage';
import ZpLink from 'components/UI/Link/ZpLink';
import { routes } from 'core/constants/routes';
import Container from 'components/Layouts/Container/Container';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';

const LinkPageFooter = () => {
  // store
  const { templateDetail } = useSelector((store: RootState) => store.templateStore);
  const { logo } = templateDetail || {};

  return (
    <footer className='footer relative z-10'>
      <Container>
        <ZpLink target='_blank' href={routes.ROOT()} external>
          <div className='flex flex-col justify-center items-center py-10'>
            {logo && <ZpImage alt={process.env.NEXT_PUBLIC_BRAND_NAME_FA || ''} width={65} height={65} src={(process.env.NEXT_PUBLIC_API_FILE_BASE_URL as string) + logo} />}
          </div>
        </ZpLink>
      </Container>
    </footer>
  );
};

export default LinkPageFooter;
